import { PropsWithChildren } from 'react';

import { hstack } from '@agentero/styles/patterns';

const datePickerContainerStyles = hstack({
	gap: '2',
	overflow: 'auto',
	scrollbarWidth: 'none',
	paddingInline: '8'
});

export const DatePickerContainer = ({ children }: PropsWithChildren) => {
	return (
		<div data-slot="date-picker-container" className={datePickerContainerStyles}>
			{children}
		</div>
	);
};

import { PropsWithChildren } from 'react';

import { Controller, RegisterOptions } from 'react-hook-form';

import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';
import { getInputStatusFromError } from '@agentero/utils';

import { Field, FieldGenericProps } from '../shared/Field';
import { InputNumber } from './fieldNumber/InputNumber';
import { InputVariants } from './fieldText/Input';

type FieldNumberProps<T extends {}> = {
	type?: 'password' | 'tel' | 'text';
	placeholder?: string;
	minValue?: number;
	maxValue?: number;
	autoFocus?: boolean;
	registerOptions?: RegisterOptions<T>;
	displayErrorMessage?: boolean;
	defaultValue?: number;
	thousandSeparator?: boolean;
	allowLeadingZeros?: boolean;
	maxLength?: number;
} & FieldGenericProps<T> &
	InputVariants;

export const FieldNumber = <T extends {}>({
	name,
	registerOptions,
	displayErrorMessage,
	placeholder,
	minValue,
	maxValue,
	autoFocus,
	children,
	disabled,
	defaultValue,
	size,
	thousandSeparator = true,
	allowLeadingZeros,
	maxLength,
	type = 'tel',
	...formGroupProps
}: PropsWithChildren<FieldNumberProps<T>>) => {
	const {
		control,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<Field
			name={name}
			nestedChildren={children}
			{...formGroupProps}
			displayErrorMessage={displayErrorMessage}>
			<Controller
				rules={registerOptions}
				name={name}
				control={control}
				render={({ field: fieldProps }) => (
					<InputNumber
						id={removeAllDots(name)}
						status={getInputStatusFromError(fieldError?.message)}
						placeholder={placeholder}
						aria-invalid={!!fieldError?.message}
						aria-errormessage={getFieldErrorId(name)}
						min={minValue}
						max={maxValue}
						autoFocus={autoFocus}
						disabled={disabled}
						thousandSeparator={thousandSeparator}
						allowLeadingZeros={allowLeadingZeros}
						defaultValue={defaultValue}
						size={size}
						maxLength={maxLength}
						type={type}
						{...fieldProps}
					/>
				)}
			/>
		</Field>
	);
};

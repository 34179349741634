import { useRef } from 'react';

import { DateValue, useDatePicker } from '@react-aria/datepicker';
import { DatePickerStateOptions, useDatePickerState } from '@react-stately/datepicker';

import { IconCalendarToday } from '@agentero/icons';
import { Box } from '@agentero/styles/jsx';

import { Calendar } from '../Calendar';
import { Dialog } from '../Dialog';
import { DateField } from './datepicker/DateInput';
import { DatePickerButton } from './datepicker/DatePickerButton';
import { DatePickerContainer } from './datepicker/DatePickerContainer';
import { DatepickerPopover } from './datepicker/DatepickerPopover';
import { InputSegment } from './shared/InputSegment';

type DatePickerProps = DatePickerStateOptions<DateValue> & {
	id: string;
	size?: 'sm' | 'md' | 'lg';
	hasCalendarPicker?: boolean;
	'aria-invalid'?: boolean;
	'aria-errormessage'?: string;
};

export const DatePicker = (props: DatePickerProps) => {
	const { hasCalendarPicker = false } = props;
	const state = useDatePickerState(props);
	const ref = useRef<HTMLDivElement>(null!);
	const { groupProps, fieldProps, buttonProps, dialogProps, calendarProps } = useDatePicker(
		props,
		state,
		ref
	);

	return (
		<Box position="relative" flexDirection="column" textAlign="left">
			<InputSegment
				{...groupProps}
				aria-invalid={props['aria-invalid']}
				aria-errormessage={props['aria-errormessage']}
				size={props.size}
				ref={ref}>
				<DatePickerContainer>
					<DateField {...fieldProps} />
				</DatePickerContainer>
				{hasCalendarPicker && (
					<DatePickerButton {...buttonProps} size={props.size} isPressed={state.isOpen}>
						<IconCalendarToday />
					</DatePickerButton>
				)}
			</InputSegment>
			{state.isOpen && (
				<DatepickerPopover triggerRef={ref} state={state} placement="bottom end" offset={8}>
					<Dialog {...dialogProps}>
						<Calendar {...calendarProps} />
					</Dialog>
				</DatepickerPopover>
			)}
		</Box>
	);
};

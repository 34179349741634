import { styled } from '@agentero/styles/jsx';

export const InputSegment = styled('div', {
	base: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'center',
		borderStyle: 'solid',
		borderWidth: '1px',
		borderColor: 'neutral.80',
		paddingLeft: '0.5rem',
		overflow: 'hidden',
		borderRadius: 'md',
		boxShadow: '1',
		'&[aria-disabled=true]': {
			cursor: 'default',
			backgroundColor: 'neutral.90',
			borderColor: 'neutral.80',
			color: 'neutral.50'
		},
		_focusWithin: {
			borderColor: 'brand',
			outlineOffset: '0.125rem',
			outlineStyle: 'solid',
			outlineWidth: '0.125rem',
			outlineColor: 'brand',

			'& button': {
				borderLeftColor: 'brand'
			}
		},
		'&:has([aria-invalid=true])': {
			borderColor: 'red',

			'& button': {
				borderLeftColor: 'red'
			}
		},
		'&:has([data-slot="date-picker-container"])': {
			paddingLeft: '0'
		}
	},
	variants: {
		size: {
			sm: {
				height: '2rem',
				textStyle: 'body.small'
			},
			md: {
				height: '2.5rem',
				textStyle: 'body.small'
			},
			lg: {
				height: '3rem',
				textStyle: 'body.base',
				paddingInline: '15px',
				borderRadius: 'lg'
			}
		}
	},
	defaultVariants: {
		size: 'md'
	}
});
